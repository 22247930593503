<template>
  <v-container class="py-12 primary">
    <v-responsive
      max-width="700"
      class="mx-auto text-center"
    >
      <svg
        class="img-fluid"
        viewBox="0 0 3137 1202"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;min-width: 160px; width: 15vw; margin-bottom: 15px;"
      ><g
        fill="rgb(110,182,64)"
        stroke="none"
        transform="matrix(4.16667,0,0,4.16667,-553.505,-42.5292)"
      ><path d="M828.025 190.236C821.624 174.689 806.383 164.629 786.568 164.629 766.449 164.629 751.208 174.689 744.807 190.236H828.025zM687.193 206.087C687.193 153.351 728.346 115.856 786.568 115.856 844.486 115.856 885.639 153.351 885.639 206.087V225.292H746.33C753.342 239.009 767.974 247.544 786.568 247.544H861.253V291.44H786.568C728.346 291.44 687.193 256.08 687.193 206.087z" /><path d="M132.841 119.512 188.063 258.481C197.735 282.826 220.744 298.556 246.678 298.556 272.187 298.556 295.05 283.181 304.926 259.386L362.977 119.512H298.194L251.958 232.36C251.122 234.396 249.168 235.711 246.976 235.711 244.748 235.711 242.781 234.367 241.964 232.286L197.669 119.512H132.841z" /><path d="M642.511 148.513C630.403 134.956 608.054 118.797 570.316 118.779L548.387 118.75V171.098C555.259 171.076 563.142 171.057 570.131 171.057 591.912 171.057 609.569 187.882 609.569 208.638 609.569 229.394 591.912 246.22 570.131 246.22 555.545 246.22 542.817 238.667 535.994 227.449 533.231 223.22 531.497 218.176 530.923 212.671 530.909 212.553 530.896 212.435 530.884 212.317 530.855 212.01 530.832 211.7 530.811 211.391 530.741 210.48 530.693 209.565 530.693 208.638V171.17H530.703V22.122H474.654V208.574C474.654 232.585 483.519 254.777 499.616 271.063 511.817 283.405 533.935 298.12 570.015 298.12 597.724 298.12 622.792 287.989 640.602 269.595 656.328 253.352 665.349 231.943 665.349 210.857 665.349 187.145 657.237 165.004 642.511 148.513z" /><path d="M382.191 119.426h55.36v172.605H382.191z" /><path d="M418.354 102.799 366.648 56.502 418.354 10.207 446.231 39.967 427.767 56.499l18.464 16.534L418.354 102.799z" /></g></svg>
    </v-responsive>

    <v-card
      class="pa-4 mx-auto"
      max-width="600"
      flat
      color="primary"
    >
      <v-row
        v-if="err"
        dense
        no-gutters
        class="px-3"
      >
        <v-col
          v-if="err"
          cols="12"
        >
          <v-alert
            color="error"
            :data-cy="err"
          >
            {{ err }}
          </v-alert>
        </v-col>
      </v-row>
      <v-card-text>
        <v-btn
          block
          class="white mt-4"
          x-large
          style="color: #440099; font-weight: 600"
          :disabled="isSignedIn"
          :loading="loadingLogin"
          @click="submit()"
        >
          {{ isSignedIn ? 'Already Logged in' : 'Sign In' }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  export default {
    data () {
      return {
        loadingLogin: false,
        isSignedIn: null,
        err: '',
        userFullData: null,
      }
    },
    computed: {
      user () {
        const userData = this.$gapi.getUserData()

        if (userData) {
          return userData
        }
        return {}
      },
    },
    methods: {
      submit () {
        this.err = ''
        this.loadingLogin = true
        this.$gapi.login().then(() => {
          this.loadingLogin = false
        })
          .catch((err) => {
            this.loadingLogin = false
            this.err = err
          })
      },
    },
  }
</script>
